html, body {
  /*overflow: hidden;*/
  /*background-color: aqua;*/
}
body {
  margin: 0;
  padding: 0;
  font-family: Fira Sans, sans-serif;
  /*-webkit-overflow-scrolling: touch;*/
}


.log{
  position: absolute;
    text-align: left;
  bottom:20px;
  left: 20px;
  min-width:40vw;
  z-index:100;
  border: 1px black solid;
  padding:10px;
  background-color:white;
  box-shadow: 2px 2px 2px grey;
}
.pdf canvas {
  max-width: calc( 100vw - 40px);
}